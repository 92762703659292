import { render, staticRenderFns } from "./PriceCell.vue?vue&type=template&id=ebd0a756&scoped=true&"
import script from "./PriceCell.vue?vue&type=script&lang=ts&"
export * from "./PriceCell.vue?vue&type=script&lang=ts&"
import style0 from "./PriceCell.vue?vue&type=style&index=0&id=ebd0a756&prod&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ebd0a756",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAvatar,VCol,VIcon,VRow,VTooltip})
